import "./category.css";
import React, { useState } from "react";
import A from "../../assets/img/categories/products/A.jpg";
import B from "../../assets/img/categories/products/B.jpg";
import C from "../../assets/img/categories/products/C.jpg";
import D from "../../assets/img/categories/products/D.jpg";

const Categories = () => {
    return (
        <>
            <div id="shopify-section-template--14132842135652__1551929568068" className="shopify-section index-section">
                <div className="container-section collections-section">
                    <div className="wrapper container">
                        <div className="row row-0 products products-grid grid-4 layout-2">
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <a href="#" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${A})`, display: 'block' }}></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">    
                                            <a href="#">
                                                <div className="more-products">
                                                    8 products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Living Room</span>
                                                    </div>
                                                </h2>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <a href="#" class="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${B})`, display: 'block' }}></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <a href="#">
                                                <div className="more-products">
                                                    4 products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Accessories</span>
                                                    </div>
                                                </h2>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <a href="#" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${C})`, display: 'block' }}></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <a href="#">
                                                <div className="more-products">
                                                    5 products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Bathroom</span>
                                                    </div>
                                                </h2>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <a href="#" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${D})`, display: 'block' }}></div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <a href="#">
                                                <div className="more-products">
                                                    3 products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Kitchen</span>
                                                    </div>
                                                </h2>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Categories;