import React from "react";
import user from "../../assets/img/user.png";
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";

const Navigation = ({ Toggle }) => {
    // get user credentials from session storage
    const SellerName = sessionStorage.getItem("seller_name");
    const ProfilePicture = sessionStorage.getItem("profile_picture");

    // function to log out
    const logOut = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        sessionStorage.clear();
        window.location.href = "/";
    };

    return (
        <header className="main-header">
            <div className="main-header-inner">
                <nav className="navbar navbar-static-top">
                    <a href="javascript:void(0)" className="sidebar-toggle" data-toggle="push-menu" role="button" aria-hidden="true" onClick={Toggle}>
                        <Icon name="HiMenuAlt2" size="1.5em" style={{ fontSize: '16px', top: '-7px', position: 'relative' }} />
                    </a>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <li>
                                <a 
                                    href="/" 
                                    className="btn btn-sm btn-success pull-left btn-site-prev" 
                                    target="_blank"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("/", "_blank");
                                    }}
                                >
                                    <Icon name="FaEye" style={{ marginRight: '8px' }} />     
                                    &nbsp;
                                    <span className="btn-site-prev-text">View Site</span>
                                </a> 
                            </li>
                            <li className="dropdown user user-menu">
                                <a href="javascript:void(0)" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" tabIndex="0">
                                    <img 
                                        src={ProfilePicture || user} 
                                        className="user-image" 
                                        alt={SellerName} 
                                    />
                                    <span className="hidden-xs">
                                        {SellerName}
                                    </span>
                                    &nbsp;
                                    {/*<i className="fa fa-caret-down caret-profile"></i>*/}
                                </a>
                                <ul className="dropdown-menu  pull-right" role="menu" aria-labelledby="user-options">
                                    <li>
                                        <a href="/seller-profile">
                                            <Icon name="MdOutlineFaceRetouchingNatural" style={{ marginRight: '5px', textAlign: 'center', width: '16px' }} />
                                            Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/settings">
                                            <Icon name="FaCog" style={{ marginRight: '5px', textAlign: 'center', width: '16px' }} />
                                            Update Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/change-password">
                                            <Icon name="FaLock" style={{ marginRight: '5px', textAlign: 'center', width: '16px' }} />
                                            Change Password
                                        </a>
                                    </li>
                                    <li className="divider"></li>
                                    <li>
                                        <form className="form-logout">
                                            <button type="submit" className="btn-logout text-left" onClick={logOut}>
                                                <Icon name="MdLogout" style={{ textAlign: 'center', width: '18px' }} />
                                                &nbsp;&nbsp;Logout
                                            </button>
                                        </form>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Navigation.propTypes = {
    Toggle: PropTypes.func.isRequired, // Define Toggle as a required function
};

export default Navigation;