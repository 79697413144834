import "./register.scss";
import { Toast } from "primereact/toast";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import HeaderCopy from "../../components/header/HeaderCopy";

const Register = () => {
  const [confirm_password, setConfirmPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [loading, setLoading] = useState(false);
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [location, setLocation] = useState("");
  const toast = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showSignUp, setShowSignUp] = useState(true);

  const handleStartHereClick = () => {
    setShowSignUp(false); // Hide the sign-up section when "Start Here" is clicked
    // Additional logic for handling the transition to the next form
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  
  const validateContact = (value) => {
  const regexp = /^\d{10}$/;
    if (!regexp.test(value)) {
      setFormError('Contact must be exactly 10 digits');
      return false;
    }
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  // todo: show toast
  const show = (message, status) => {
    toast.current.show({
      severity: status === 200 ? "success" : status === 401 ? "error" : "warning",
      summary: message,
      detail: "",
      duration: 10000, // Optional
      sticky: true, // Keep toast visible until dismissed
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Handle potential errors in user input
    if (!validateContact(contact)) {
      setLoading(false);
      show("Please enter a valid 10-digit contact number.", 400);
      return;
    }
    if (!isCheckboxChecked) {
      setLoading(false);
      show("Please accept the terms and conditions to proceed.", 400);
      return;
    }

    if (password !== confirm_password) {
      setLoading(false);
      show("Password and Confirm Password does not match", 400);
    } else {
      return axios
        .post(`${process.env.REACT_APP_Base_url}/shoppers/register`, {
          first_name,
          last_name,
          email, 
          contact,
          location,
          password,
        })
        .then((res) => {
          console.log(res.data);
          show(res.data.message, res.data.status);
          setLoading(false);

          if (res.data.status === 201) {
            document.getElementById("finish").checked = true;
            // Inform the user to check their email for activation instructions
            show(
            "Registration successful! Please check your email for activation instructions.",
            200
          );
          //window.location.href = "/login";
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (err.response && err.response.status === 400) {
            const errorMessage = err.response.data.message;
            // Check for specific error messages from the backend
            if (errorMessage === "The mobile number you entered has already been used. Enter a new number") {
            // Display specific error message for duplicate contact number
              show(errorMessage, 400);
            } else {
            // Display a generic error message for other errors
            show("An error occurred. Please try again later.", 400);
            }
          } else {
          // Display a generic error message for other errors
          show("An error occurred. Please try again later.", 400);
          }
      });
    }
};


  return (
    <>
      <Toast ref={toast} />
      
      <HeaderCopy />
      
      <section className="vh-75 d-flex justify-content-center align-items-center" style={{ backgroundColor: "whitesmoke", paddingTop: "200px" }}>
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  {/*<div className="login_page bg-white shadow rounded p-4">*/}
                  <div className="register-seller-container">
                    <input className="c-checkbox" type="checkbox" id="start" />
                    <input className="c-checkbox" type="checkbox" id="progress2" />
                    <input className="c-checkbox" type="checkbox" id="progress3" />
                    <input className="c-checkbox" type="checkbox" id="progress4" />
                    <input className="c-checkbox" type="checkbox" id="progress5" />
                    <input className="c-checkbox" type="checkbox" id="progress6" />
                    <input className="c-checkbox" type="checkbox" id="progress7" />
                    <input className="c-checkbox" type="checkbox" id="progress8" />
                    <input className="c-checkbox" type="checkbox" id="finish" />
                    <div className="c-form__progress"></div>

                    <div className="c-formContainer">
                      <div className="c-welcome">Welcome {first_name}!</div>
                      
                      <form className="c-form" onSubmit={handleSubmit}>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="firstname">
                            <input
                              type="text"
                              id="firstname"
                              className="c-form__input"
                              placeholder=" "
                              pattern="^[a-zA-Z]+(?: [a-zA-Z]+)*$"      //"[^\s]+"
                              required
                              value={first_name}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            
                            <label className="c-form__next" htmlFor="progress2" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">Enter your first name.</span>
                            <b className="c-form__border"></b>
                          </label>
                        </div>
                        

                        <div className="c-form__group">
                          <label className="c-form__label" htmlfor="surname">
                            <input
                              type="text"
                              id="surname"
                              className="c-form__input"
                              placeholder=" "
                              pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$" //"^[a-zA-Z]+(?: [a-zA-Z]+)*$" or "[^\s]+"
                              required
                              value={last_name}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                            
                            <label className="c-form__next" htmlFor="progress3" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">Enter your surname.</span>
                            <b className="c-form__border"></b>
                          </label>
                        </div>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="location">
                            <input
                              type="text"
                              id="location"
                              className="c-form__input"
                              placeholder=" "
                              pattern="[^\s]+"
                              required
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                            />

                            <label className="c-form__next" htmlFor="progress4" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">Where are you located?</span>
                            <b className="c-form__border"></b>
                          </label>
                        </div>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="femail">
                            <input
                              type="email"
                              id="femail"
                              className="c-form__input"
                              placeholder=" "
                              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />

                            <label className="c-form__next" htmlFor="progress5" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">What's your email?</span>
                            <b className="c-form__border"></b>
                          </label>
                        </div>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="contact">
                            <input
                              type="tel"
                              id="contact"
                              className={`c-form__input ${formError ? 'is-invalid' : ''}`} //"c-form__input"
                              placeholder=" "
                              pattern="^0\d{9}$"
                              required
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                            />
                            {formError && <p className="text-danger">{formError}</p>}

                            <label className="c-form__next" htmlFor="progress6" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">Enter your mobile number.</span>
                            <b className="c-form__border"></b>
                          </label>
                        </div>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="fpass">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="fpass"
                              className="c-form__input"
                              placeholder=" "
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                              className={`mdi mdi-eye${showPassword ? '-off' : ''}`}
                              style={{
                                position: 'relative',
                                display: 'inline-block', // adjust as needed
                                verticalAlign: 'middle', // adjust as needed
                                marginLeft: '-30px', // adjust padding as needed
                                marginRight: '5px',
                                marginTop: '20px',
                                color: '#7c7c7c', // adjust color as needed
                                cursor: 'pointer',
                                top: '-10px',
                              }}
                              onClick={togglePasswordVisibility}
                            />

                            <label className="c-form__next" htmlFor="progress7" role="button">
                              <span className="c-form__nextIcon"></span>
                            </label>

                            <span className="c-form__groupLabel">Create your password.</span>
                            <b class="c-form__border"></b>
                          </label>
                        </div>

                        <div className="c-form__group">
                          <label className="c-form__label" htmlFor="confirm-fpass">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="confirm-fpass"
                              className="c-form__input"
                              placeholder=" "
                              required
                              value={confirm_password}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {password !== confirm_password && (
                              <p className="text-danger">Password mismatch.</p>
                            )}
                            <i
                              className={`mdi mdi-eye${showPassword ? '-off' : ''}`}
                              style={{
                                position: 'relative',
                                display: 'inline-block', // adjust as needed
                                verticalAlign: 'middle', // adjust as needed
                                marginLeft: '-30px', // adjust padding as needed
                                marginRight: '5px',
                                marginTop: '20px',
                                color: '#7c7c7c', // adjust color as needed
                                cursor: 'pointer',
                                top: '-10px',
                              }}
                              onClick={togglePasswordVisibility}
                            />

                            <label className="c-form__next" htmlfor="finish" role="button" disabled={!isCheckboxChecked} onClick={handleSubmit}>
                              {loading ? (
                                <span className="c-form__nextIcon spinner"></span>
                              ) : (
                                <span className="c-form__nextIcon"></span>
                              )}
                            </label>
                            
                            {/* {loading ? (
                              <div className="col-md-12">
                                <button className="btn btn-primary w-100" disabled type="submit">
                                  Loading ...
                                </button>
                              </div>
                            ) : null} */}

                            <span className="c-form__groupLabel">Confirm your password.</span>
                            <b class="c-form__border"></b>
                          </label>
                          
                          <div className="col-md-12">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox custom-checkbox-terms">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customCheck1"
                                required
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck1">
                                I accept{" "}
                                <a href="/terms" className="text-primary">
                                  Terms & Conditions
                                </a>
                              </label>
                            </div>
                          </div>
                        </div>

                     </div>
                        
                    <label className="c-form__toggle" htmlFor="start" onClick={handleStartHereClick}>Start Here<span className="c-form__toggleIcon"></span></label>
                      {showSignUp && (  // Render the sign-up section only if showSignUp is true
                        <div className="col-lg-12 mt-4 text-center">
                          <h6>Or Sign Up With</h6>
                          <ul className="list-unstyled social-icon mb-0 mt-3">
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-facebook"
                                  title="Facebook"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-google-plus"
                                  title="Google"
                                />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="#/" className="rounded">
                                <i
                                  className="mdi mdi-github-circle"
                                  title="Github"
                                />
                              </a>
                            </li>
                          </ul>
                          {/*end icon*/}
                        </div>
                        )}
                      </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* footer start 
    <Footer />
    {/* footer end */}
    </>
  );
};                        
                       
export default Register;
