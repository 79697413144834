import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";

const Terms = () => {

  return (
    <>
      <HeaderCopy />

      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="blog-content">
              <nav class="nav-breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Terms &amp; Conditions</li>
                </ol>
              </nav>
              <h1 class="page-title">Terms &amp; Conditions</h1>
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-text-content post-text-responsive">
                    <p><strong>Terms and Conditions - Online Store</strong></p>
<ol>
<li><strong>Introduction</strong></li>
</ol>
<p>1.1 These terms and conditions ("Agreement") govern your use of vinceeimports.tech (referred to as the "Website" or "our Website") provided by vincee (referred to as "we," "us," or "our").</p>
<p>1.2 By accessing or using the Website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use the Website.</p>
<ol start="2">
<li><strong>Product Information and Accuracy</strong></li>
</ol>
<p>2.1 We strive to provide accurate and up-to-date information regarding our products, including descriptions, prices, and availability. However, we do not guarantee the accuracy, completeness, or reliability of any information on the Website.</p>
<p>2.2 The colors, sizes, and appearance of products may vary depending on your computer monitor or mobile device, and we cannot guarantee that the product images displayed on the Website will accurately represent the actual products.</p>
<ol start="3">
<li><strong>Orders and Payments</strong></li>
</ol>
<p>3.1 By placing an order through our Website, you represent that you are legally capable of entering into binding contracts and that the information you provide is accurate and complete.</p>
<p>3.2 All prices listed on the Website are in GHS and do not include applicable taxes or shipping charges, unless otherwise stated.</p>
<p>3.3 We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing or product information, or suspicion of fraudulent activity. If we cancel your order, we will refund any payments made after further investigations.</p>
<ol start="4">
<li><strong>Shipping and Delivery</strong></li>
</ol>
<p>4.1 We will make reasonable efforts to ship your order within the estimated timeframe stated on the Website. However, we do not guarantee delivery dates, as they may vary depending on your location, shipping method,time of shipping and unforeseen circumstances.</p>
<p>4.2 Once your order is shipped, the risk of loss and title for the products pass to you. We are not responsible for any lost, stolen, or damaged items during transit.</p>
<ol start="5">
<li><strong>Returns and Exchanges</strong></li>
</ol>
<p>5.1 We accept returns and exchanges in accordance with our Return Policy, which is available on the Website. Please review the Return Policy carefully before making a purchase.</p>
<p>5.2 You are responsible for any return shipping costs, unless the return is due to our error or a defective product.</p>
<ol start="6">
<li><strong>Intellectual Property</strong></li>
</ol>
<p>6.1 All content, designs, logos, images, and other materials displayed on the Website are the property of wearchron.com or its licensors and are protected by intellectual property laws.</p>
<p>6.2 You may not use, reproduce, modify, or distribute any content from the Website without our prior written consent.</p>
<ol start="7">
<li><strong>Limitation of Liability</strong></li>
</ol>
<p>7.1 To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use of the Website or the products purchased through the Website.</p>
<ol start="8">
<li><strong>Indemnification</strong></li>
</ol>
<p>8.1 You agree to indemnify, defend, and hold harmless wearchron.com, its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use of the Website or any violation of these terms and conditions.</p>
<ol start="9">
<li><strong>Governing Law and Jurisdiction</strong></li>
</ol>
<p>9.1 These terms and conditions shall be governed by and construed in accordance with the laws of Ghana and west africa. Any dispute arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Ghana and west africa.</p>                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
      <Footer />
    </>
  );
};

export default Terms;
