import React from 'react';
import { IconContext } from 'react-icons';
import { FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus } from "react-icons/fa6";
import { FaEnvelope, FaFacebookF, FaFilter, FaEdit, FaHome, FaFile, FaShoppingBasket, FaCog, FaComments, 
        FaQuestion, FaLock, FaWindowClose, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck,
        FaPhone } from "react-icons/fa";
import { BiLogoGmail, BiChevronDown, BiChevronRight, BiChevronLeft, BiLogInCircle, BiLogOutCircle } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { BsHeart, BsHeartFill, BsCart3, BsCartCheck, BsCartCheckFill } from "react-icons/bs";
import { MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline } from "react-icons/md";
import { TbTruckDelivery } from 'react-icons/tb';
import { IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { HiMenuAlt2 } from "react-icons/hi";
import { FcApproval } from "react-icons/fc";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
// Import other icons as needed

const iconComponents = {
    FaEnvelope, FaFacebookF, FaFilter, FaEdit, FaHome, FaFile, FaShoppingBasket, FaQuestion, FaCog, FaComments, 
    FaLock, FaWindowClose, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck, FaPhone,
    FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus,
    BiLogoGmail, BiChevronDown, BiChevronLeft, BiChevronRight, BiLogInCircle, BiLogOutCircle,
    BsHeart, BsHeartFill, BsCartCheck,
    CiSearch,
    MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline,
    TbTruckDelivery,
    IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation,
    RxDashboard,
    HiMenuAlt2,
    FcApproval,
    VscEye, VscEyeClosed,
  // Add other icons here
};

const Icon = ({ 
    name, 
    size = '1em', 
    color = 'currentColor', 
    style = {}, 
    className = ''
}) => {
    const IconComponent = iconComponents[name];
  
    if (!IconComponent) {
      console.warn(`Icon ${name} not found`);
      return null;
    }
  
    return (
        <IconContext.Provider value={{ size, color }}>
            <div 
                style={{ display: 'inline-block', ...style }}
                className={className}
            >
                <IconComponent />
            </div>
        </IconContext.Provider>
    );
};
  
export default Icon;

// Usage:
// <Icon name="FaShoppingBag" size="1.5em" color="#333" />
// import Icon from "../icon/Icon";
