import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

// Components
import HeaderCopy from "../../components/header/HeaderCopy";
import Footer from "../../components/footer/Footer";
import { ProductProps } from "../../types/Types";
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";
import Stars from '../../components/stars/Stars';
import PriceFilter, { PriceFilterRef } from '../../components/product filter/PriceFilter';
import Loader from "../../components/loader/Loader";
import ProductSectionFilter from "../../components/product filter/ProductSectionFilter";
import Icon from "../../components/icon/Icon";

const ProductBySection = () => {
    const location = useLocation();
    const itemSection: string | null = location.state?.data || null;
    const [products, setProducts] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<string>('most_recent');
    const [minPrice, setMinPrice] = useState<number | null>(null);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const [productName, setProductName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const priceFilterRef = useRef<PriceFilterRef>(null);

    // Fetch active products with sorting and filtering
    const fetchProducts = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_Base_url}/products/get_product_category`, {
                product_section: itemSection,
                product_name: productName,
                sort: sortCriteria,
                minPrice: minPrice !== null ? minPrice : undefined,
                maxPrice: maxPrice !== null ? maxPrice : undefined,
                page: currentPage
            });
            if (response.data.status === 200) {
                setProducts(response.data.product);
                if (response.data.product && response.data.product.seller_id) {
                    sessionStorage.setItem('seller_id', response.data.product.seller_id);
                }
                setTotalPages(response.data.total_pages);
            } else {
                setProducts([]);
                setTotalPages(1);
            }
        } catch (err) {
                console.error(err);
            } finally {
            setIsLoading(false);
        }
        // Cleanup function to clear seller_id from session storage on unmount
        return () => {
            sessionStorage.removeItem('seller_id');
        };
    };

    useEffect(() => {
        setIsLoading(true);
        fetchProducts();
    }, [itemSection, productName, currentPage, sortCriteria, minPrice, maxPrice]);

    // Handle search button click
    const handleSearch = () => {
        fetchProducts();
    };

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // Function to sort products by highest and lowest prices, most recent and rating
    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSortCriteria(e.target.value);
        setCurrentPage(1); // Reset to first page on sort change
    };

    // Function to handle the filtering of products by price range
    const handleFilter = (min: number, max: number) => {
        setMinPrice(min);
        setMaxPrice(max);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Function to clear the minimum price input
    const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMinPrice(null); // Reset minPrice to null
        priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    // Function to clear the maximum price input
    const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMaxPrice(null); // Reset maxPrice to null
        priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    // Function to format price
    const formatPrice = (price: number) => {
        // Format the number to two decimal places
        const formattedPrice = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Prepend the cedi symbol
        return `¢${formattedPrice}`;
    };

    return (
        <>
            {/* Header Begin */}
            <HeaderCopy />

            {/* Product Section Start */}
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-products">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    {location.state && (
                                        <li className="breadcrumb-item active">{location.state.data}</li>
                                    )}
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 product-list-header">
                            {location.state && (
                                <h1 className="page-title product-list-title">{location.state.data}</h1>
                            )}
                            <div className="product-sort-by">
                                <span className="span-sort-by">Sort By:</span>
                                <div className="sort-select">
                                    <select 
                                        id="select_sort_items" 
                                        className="custom-select" 
                                        value={sortCriteria}
                                        onChange={handleSortChange}
                                    >
                                        <option value="most_recent">Most Recent</option>
                                        <option value="lowest_price">Lowest Price</option>
                                        <option value="highest_price">Highest Price</option>
                                        <option value="rating">Highest Rating</option>
                                    </select>
                                </div>
                            </div>
                            <button 
                                className="btn btn-filter-products-mobile" 
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#collapseFilters" 
                                aria-expanded="false" 
                                aria-controls="collapseFilters"
                            >
                                <Icon name="FaFilter" />&nbsp;Filter Products                
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 col-sidebar-products">
                            <div id="collapseFilters" className="product-filters">
                                <div className="filter-item">
                                    <div className="profile-search">
                                        <input 
                                            type="search" 
                                            name="search" 
                                            id="input_search_product" 
                                            className="form-control form-input profile-search" 
                                            placeholder="Search"
                                            value={productName} // Bind state to the input value
                                            onChange={(e) => setProductName(e.target.value)} // Update state on input change 
                                        />
                                        <button 
                                            id="btn_search_product" 
                                            className="btn btn-default btn-search"
                                            onClick={handleSearch} // Trigger search on button click
                                        >
                                            <Icon name="CiSearch" />
                                        </button>
                                    </div>
                                </div>
                                <ProductSectionFilter />
                                {/* Render PriceFilter component and pass handleFilter function */}
                                <PriceFilter ref={priceFilterRef} isLoading={isLoading} onFilter={handleFilter} />
                            </div>
                            <div className="row-custom"></div>
                        </div>
                        
                        <div className="col-12 col-md-9 col-content-products">
                            <div className="filter-reset-tag-container">
                                {minPrice !== null && (
                                    <div className="filter-reset-tag">
                                        <div className="left">
                                            <a href="javascript:void(0)" onClick={(e) => {
                                                e.preventDefault(); 
                                                handleResetMinPrice(e);
                                            }}>
                                                <Icon name="MdClose" />
                                            </a>
                                        </div>
                                        <div className="right">
                                            <span className="reset-tag-title">Price(¢)</span>
                                            <span>Min: {minPrice}</span>
                                        </div>
                                    </div>
                                )}
                                {maxPrice !== null && (
                                    <div className="filter-reset-tag">
                                        <div className="left">
                                            <a href="javascript:void(0)" onClick={(e) => {
                                                e.preventDefault();
                                                handleResetMaxPrice(e);
                                            }}>
                                                <Icon name="MdClose" />
                                            </a>
                                        </div>
                                        <div className="right">
                                            <span className="reset-tag-title">Price(¢)</span>
                                            <span>Max: {maxPrice}</span>
                                        </div>
                                    </div>
                                )}
                                {(minPrice !== null || maxPrice !== null) && (
                                    <a href="javascript:void(0)" className="link-reset-filters" onClick={(e) => {
                                        e.preventDefault();
                                        handleResetMinPrice(e);
                                        handleResetMaxPrice(e);
                                    }}>
                                        Reset Filters
                                    </a>
                                )}
                            </div>
                        
                            <div className="product-list-content">
                                {isLoading ? (
                                    <Loader loading={isLoading} />
                                ) : (
                                    <div className="row row-product">
                                        {products.length === 0 ? (
                                            <div className="col-12">
                                                <p className="no-records-found">No products found!</p>
                                            </div>
                                        ) : (
                                            products.map((product: ProductProps) => {
                                                return (
                                                    <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-product" key={product.id}>
                                                        <div className="product-item">        
                                                            <div className="row-custom product-multiple-image">
                                                                <div className="img-product-container">
                                                                    <Link to={`/details`} state={{ data: product.id }}>
                                                                        <img
                                                                            src={product.main_image}
                                                                            alt={product.product_name}
                                                                            className="img-fluid img-product ls-is-cached lazyloaded"
                                                                            loading="lazy"
                                                                        />
                                                                        <div className="thumbnail hover">
                                                                            <img
                                                                                src={product.product_images[0]}
                                                                                alt={product.product_name}
                                                                                className="img-fluid img-product img-second ls-is-cached lazyloaded"
                                                                                loading="lazy"
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <WishlistButton productId={product.id} />
                                                                            </li>
                                                                            <li>
                                                                                <a href="/cart" className="tooltip-container">
                                                                                    <Icon name="IoCartOutline" size="1.5em" /> 
                                                                                    <div className="tooltip">
                                                                                        <span className="tooltip-text">Add to Cart</span>
                                                                                        <span className="tooltip-arrow"></span>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <Link to={`/details`} state={{ data: product.id }}>
                                                                                <li>
                                                                                    <a href="/details" className="tooltip-container">
                                                                                        <Icon name="FaEye" size="1.5em" /> 
                                                                                        <div className="tooltip">
                                                                                            <span className="tooltip-text">View Details</span>
                                                                                            <span className="tooltip-arrow"></span>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                            </Link>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="sub-actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a className="item-option btn-add-remove-wishlist">
                                                                                    <WishlistButton productId={product.id} />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="/cart" className="item-option tooltip-container">
                                                                                    <Icon name="IoCartOutline" size="1.5em" /> 
                                                                                    <div className="tooltip">
                                                                                        <span className="tooltip-text">Add to Cart</span>
                                                                                        <span className="tooltip-arrow"></span>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row-custom item-details">
                                                                <h3 className="product-title">
                                                                    <Link to={`/details`} state={{ data: product.id }}>
                                                                        {product.product_name}
                                                                    </Link>
                                                                </h3>
                                                                <p className="product-user text-truncate">
                                                                    <a href="/profile">{product.company_name}</a>
                                                                </p>
                                                                <div className="product-item-rating">
                                                                    <div className="rating">
                                                                        <Stars productId={product.id} />
                                                                    </div>
                                                                        <WishlistTotal productId={product.id} />
                                                                </div>
                                                                <Link to={`/details`} state={{ data: product.id }}>
                                                                    <div className="item-meta">
                                                                        <span className="price">{formatPrice(product.product_price)}</span>
                                                                    </div>
                                                                </Link>
                                                            </div>            
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="product-list-pagination">
                                <div className="float-right">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination">
                                            {Array.from({ length: totalPages }, (_, index) => (
                                                <li
                                                    key={index + 1}
                                                    className={currentPage === index + 1 ? 'active' : ''}
                                                >
                                                    <a href="javascript:void(0)" onClick={() => handlePageChange(index + 1)}>
                                                        {index + 1}
                                                    </a>
                                                </li>
                                            ))}
                                            {/* Conditionally render the "Next" button 
                                             {totalPages > 3 && (
                                                <li>
                                                    <a href="javascript:void(0)" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                                        <span aria-hidden="true">»</span>
                                                    </a>
                                                </li>
                                            )}*/}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
            {/* Product Section End */}

            {/* Footer Section Begin */}
            <Footer />
        </>
    );
};

export default ProductBySection;