import React, { useEffect, useState } from "react";
import A from "../../assets/img/newsletter.png";
import Icon from "../icon/Icon";

const Newsletter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    // function to toggle the newsletter once
    useEffect(() => {
    const isNewsletterShown = localStorage.getItem('newsletterShown');
    if (!isNewsletterShown) {
        const timer = setTimeout(() => {
        setIsOpen(true);
        localStorage.setItem('newsletterShown', true ? 'true' : 'false');
        }, 900000); // Set the delay to 30 seconds (30000 ms)

        return () => clearTimeout(timer);
    }
    }, []);

    // handles closing of the modal
    const handleClose = () => {
        setIsOpen(false);
        setResponseMessage('');
    };

    // handles email change
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    // handles submit
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Perform the newsletter subscription logic here
        // For example, you can make an API call to subscribe the email
        // Here we'll just simulate a successful submission
        setResponseMessage('Thank you for subscribing!');
        setEmail('');
    };
  
    return (
        <div>
            {isOpen && (
                <div id="modal_newsletter" className="modal fade modal-center modal-newsletter show" role="dialog" aria-modal="true" style={{ paddingRight: '17px', display: 'block' }}>
                    <div className="overlay" style={{ background: 'rgba(0, 0, 0, .3)' }}></div>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-6 col-left">
                                        <img src={A} className="newsletter-img" />
                                    </div>
                                    <div className="col-6 col-right">
                                        <div className="newsletter-form">
                                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={handleClose}>
                                                <Icon name="MdClose" />
                                            </button>
                                            <h4 className="modal-title">Join Our Newsletter</h4>
                                            <p className="modal-desc">Join our subscribers list to get the latest news, updates and special offers directly in your inbox</p>
                                            <form id="form_newsletter_modal" className="form-newsletter" data-form-type="modal" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <div className="modal-newsletter-inputs">
                                                        <input type="email" name="email" value={email} onChange={handleEmailChange} className="form-control form-input newsletter-input" placeholder="Enter your email" />
                                                        <button type="submit" id="btn_modal_newsletter" className="btn">Subscribe</button>
                                                    </div>
                                                </div>
                                                <div id="modal_newsletter_response" className="text-center modal-newsletter-response">
                                                    {responseMessage && <p>{responseMessage}</p>}
                                                    <div className="form-group text-center m-b-0 text-close">
                                                        <button type="button" className="text-close" data-dismiss="modal" onClick={handleClose}>
                                                            No, thanks
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Newsletter;