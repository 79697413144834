import React, { useState, useEffect } from "react";
//import MagneticHoverEffect from './MagneticHoverEffect';
import './MagneticHoverEffect.css';
import A from "../../assets/img/categories/cat-jewel.jpg";
import B from "../../assets/img/categories/cat-clothing.jpg";
import C from "../../assets/img/categories/cat-footwear.png";
import D from "../../assets/img/categories/cat-shoes.jpg";
import E from "../../assets/img/categories/cat-gem.jpg";
import F from "../../assets/img/categories/cat-ladies.jpg";
import G from "../../assets/img/categories/cat-birk.png";
import H from "../../assets/img/categories/cat-nike.jpg";
import I from "../../assets/img/categories/cat-brands1.jpg";
import J from "../../assets/img/categories/cat-brands2.jpg";
import K from "../../assets/img/categories/cat-blender.jpg";
import L from "../../assets/img/categories/cat-cookware.jpg";
import M from "../../assets/img/categories/cat-headset.jpg";
import N from "../../assets/img/categories/cat-airpod.jpg";
import O from "../../assets/img/categories/cat-smartwatch.jpg";
import P from "../../assets/img/categories/cat-art.jpg";
import Q from "../../assets/img/categories/cat-clothes.jpg";
import R from "../../assets/img/categories/cat-shoe.jpg";


const Navigation = () => {
    const [isMenDropdownVisible, setMenDropdownVisible] = useState(false);
    const [isWomenDropdownVisible, setWomenDropdownVisible] = useState(false);
    const [isBrandsDropdownVisible, setBrandsDropdownVisible] = useState(false);
    const [isHomeDropdownVisible, setHomeDropdownVisible] = useState(false);
    const [isElectronicDropdownVisible, setElectronicDropdownVisible] = useState(false);
    const [isKidsDropdownVisible, setKidsDropdownVisible] = useState(false);

    useEffect(() => {
    const menuItems = document.querySelectorAll('.nav-main-category');
    const backdrop = document.querySelector('.backdrop');

    menuItems.forEach((item) => {
      item.addEventListener('mouseenter', (e) => {
        const { width, left } = item.getBoundingClientRect();
        backdrop.style.width = `${width}px`;
        backdrop.style.transform = `translateX(${left}px)`;
      });
    });

    document.querySelector('.menu-container').addEventListener('mouseleave', () => {
      backdrop.style.width = '0';
    });
  }, []);
  

    const handleMenMouseEnter = () => {
        setMenDropdownVisible(true);
    };

    const handleMenMouseLeave = () => {
        setMenDropdownVisible(false);
    };

    const handleWomenMouseEnter = () => {
        setWomenDropdownVisible(true);
    };

    const handleWomenMouseLeave = () => {
        setWomenDropdownVisible(false);
    };

    const handleBrandsMouseEnter = () => {
        setBrandsDropdownVisible(true);
    };

    const handleBrandsMouseLeave = () => {
        setBrandsDropdownVisible(false);
    };

    const handleHomeMouseEnter = () => {
        setHomeDropdownVisible(true);
    };

    const handleHomeMouseLeave = () => {
        setHomeDropdownVisible(false);
    };

    const handleElectronicMouseEnter = () => {
        setElectronicDropdownVisible(true);
    };

    const handleElectronicMouseLeave = () => {
        setElectronicDropdownVisible(false);
    };
    
    const handleKidsMouseEnter = () => {
        setKidsDropdownVisible(true);
    };

    const handleKidsMouseLeave = () => {
        setKidsDropdownVisible(false);
    };

    return (
        <>
            <div className="nav-main">
                <div className="container">
                    <div className="navbar navbar-light navbar-expand">
                    
                        <ul className="nav navbar-nav mega-menu">
    <div className="menu-container">
    <div className="backdrop"></div>
                            <li className="nav-item dropdown" data-category-id="1" onMouseEnter={handleMenMouseEnter} onMouseLeave={handleMenMouseLeave}>
                            <a id="nav_main_category_1" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="1" data-parent-id="0" data-has-sb="1">MEN</a>
                                <div id="mega_menu_content_1" className="dropdown-menu mega-menu-content" style={{ display: isMenDropdownVisible ? "block" : "none" }} onMouseEnter={handleMenMouseEnter} onMouseLeave={handleMenMouseLeave}>
                                    <div class="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_9" href="/product-category" className="second-category nav-main-category" data-id="9" data-parent-id="1" data-has-sb="1">ACCESSORIES</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_12" href="#" class="nav-main-category " data-id="12" data-parent-id="9" data-has-sb="0">COLOGNE</a></li>
                                                                <li><a id="nav_main_category_13" href="#" class="nav-main-category " data-id="13" data-parent-id="9" data-has-sb="0">HATS</a></li>
                                                                <li><a id="nav_main_category_14" href="#" class="nav-main-category " data-id="14" data-parent-id="9" data-has-sb="0">JEWELRIES</a></li>
                                                                <li><a id="nav_main_category_26" href="#" class="nav-main-category " data-id="26" data-parent-id="9" data-has-sb="0">TIES</a></li>
                                                                <li><a id="nav_main_category_11" href="#" class="nav-main-category " data-id="11" data-parent-id="9" data-has-sb="0">OTHERS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_4" href="#" class="second-category nav-main-category" data-id="4" data-parent-id="1" data-has-sb="1">CLOTHING</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_15" href="#" class="nav-main-category " data-id="15" data-parent-id="4" data-has-sb="0">JACKETS</a></li>
                                                                <li><a id="nav_main_category_27" href="#" class="nav-main-category " data-id="27" data-parent-id="4" data-has-sb="0">TOPS</a></li>
                                                                <li><a id="nav_main_category_21" href="#" class="nav-main-category " data-id="21" data-parent-id="4" data-has-sb="0">SHORTS/TROUSERS</a></li>
                                                                <li><a id="nav_main_category_19" href="#" class="nav-main-category " data-id="19" data-parent-id="4" data-has-sb="0">OTHERS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_5" href="#" className="second-category nav-main-category" data-id="5" data-parent-id="1" data-has-sb="1">FOOTWEAR</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_28" href="#" className="nav-main-category " data-id="28" data-parent-id="5" data-has-sb="0">SNEAKERS</a></li>
                                                                <li><a id="nav_main_category_17" href="#" className="nav-main-category " data-id="17" data-parent-id="5" data-has-sb="0">SHOES</a></li>
                                                                <li><a id="nav_main_category_20" href="#" className="nav-main-category " data-id="20" data-parent-id="5" data-has-sb="0">SLIPPERS</a></li>
                                                                <li><a id="nav_main_category_18" href="#" className="nav-main-category " data-id="18" data-parent-id="5" data-has-sb="0">OTHERS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={A} data-src="#" alt="ACCESSORIES" className="img-fluid lazyloaded" />
                                                    <span>ACCESSORIES</span>
                                                </a>
                                            </div>
                                            <div class="nav-category-image">
                                                <a href="#">
                                                    <img src={C} data-src="#" alt="FOOTWEAR" className="img-fluid lazyloaded" />
                                                    <span>FOOTWEAR</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={B} data-src="#" alt="CLOTHING" className="img-fluid lazyloaded" />
                                                    <span>CLOTHING</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>                
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="2" onMouseEnter={handleWomenMouseEnter} onMouseLeave={handleWomenMouseLeave}>
                               <a id="nav_main_category_2" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="2" data-parent-id="0" data-has-sb="1">WOMEN</a>
                                <div id="mega_menu_content_2" className="dropdown-menu mega-menu-content" style={{ display: isWomenDropdownVisible ? "block" : "none" }} onMouseEnter={handleWomenMouseEnter} onMouseLeave={handleWomenMouseLeave}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_10" href="#" className="second-category nav-main-category" data-id="10" data-parent-id="2" data-has-sb="1">ACCESSORIES</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_24" href="#" className="nav-main-category " data-id="24" data-parent-id="10" data-has-sb="0">HAIR WEARS &amp; BONNETS</a></li>
                                                                <li><a id="nav_main_category_25" href="#" className="nav-main-category " data-id="25" data-parent-id="10" data-has-sb="0">NECKLACES AND ANKLETS</a></li>
                                                                <li><a id="nav_main_category_35" href="#" className="nav-main-category " data-id="35" data-parent-id="10" data-has-sb="0">PERFUMES &amp; BEAUTY</a></li>
                                                                <li><a id="nav_main_category_31" href="#" className="nav-main-category " data-id="31" data-parent-id="10" data-has-sb="0">BAGS</a></li>
                                                                <li><a id="nav_main_category_16" href="#" className="nav-main-category " data-id="16" data-parent-id="10" data-has-sb="0">EYEWEAR, BEAUTY &amp; COSMETICS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_6" href="#" className="second-category nav-main-category" data-id="6" data-parent-id="2" data-has-sb="1">CLOTHING</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_22" href="#" className="nav-main-category " data-id="22" data-parent-id="6" data-has-sb="0">FEMALE TOPS</a></li>
                                                                <li><a id="nav_main_category_23" href="#" className="nav-main-category " data-id="23" data-parent-id="6" data-has-sb="0">FEMALE TROUSERS AND SHORTS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_7" href="#" className="second-category nav-main-category" data-id="7" data-parent-id="2" data-has-sb="1">FOOTWEAR</a>
                                                            <ul>
                                                                <li><a id="nav_main_category_29" href="#" className="nav-main-category " data-id="29" data-parent-id="7" data-has-sb="0">SHOES &amp; HEELS</a></li>
                                                                <li><a id="nav_main_category_30" href="#" className="nav-main-category " data-id="30" data-parent-id="7" data-has-sb="0">SNEAKERS</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-category-images">
                                            <div className="nav-category-image">
                                            <a href="#">
                                                <img src={D} data-src="#" alt="FOOTWEAR" className="img-fluid ls-is-cached lazyloaded" />
                                                <span>FOOTWEAR</span>
                                            </a>
                                            </div>
                                            <div className="nav-category-image">
                                            <a href="#">
                                                <img src={E} data-src="#" alt="ACCESSORIES" className="img-fluid lazyloaded" />
                                                <span>ACCESSORIES</span>
                                            </a>
                                            </div>
                                            <div className="nav-category-image">
                                            <a href="#">
                                                <img src={F} data-src="#" alt="CLOTHING" className="img-fluid lazyloaded" />
                                                <span>CLOTHING</span>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="8" onMouseEnter={handleBrandsMouseEnter} onMouseLeave={handleBrandsMouseLeave}>
                            <a id="nav_main_category_8" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="8" data-parent-id="0" data-has-sb="1">BRANDS</a>
                                <div id="mega_menu_content_8" className="dropdown-menu mega-menu-content" style={{ display: isBrandsDropdownVisible ? "block" : "none" }} onMouseEnter={handleBrandsMouseEnter} onMouseLeave={handleBrandsMouseLeave}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_36" href="#" className="second-category nav-main-category" data-id="36" data-parent-id="8" data-has-sb="0">Hermès</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_34" href="#" className="second-category nav-main-category" data-id="34" data-parent-id="8" data-has-sb="0">NIKE</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_33" href="#" className="second-category nav-main-category" data-id="33" data-parent-id="8" data-has-sb="0">ADIDAS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_32" href="#" className="second-category nav-main-category" data-id="32" data-parent-id="8" data-has-sb="0">BIRKENSTOCK</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_38" href="#" className="second-category nav-main-category" data-id="38" data-parent-id="8" data-has-sb="0">BURBERRY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_37" href="#" className="second-category nav-main-category" data-id="37" data-parent-id="8" data-has-sb="0">BALENCIAGA</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_39" href="#" className="second-category nav-main-category" data-id="39" data-parent-id="8" data-has-sb="0">BINATONE</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_40" href="#" className="second-category nav-main-category" data-id="40" data-parent-id="8" data-has-sb="0">SILVERCREST</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_41" href="#" className="second-category nav-main-category" data-id="41" data-parent-id="8" data-has-sb="0">KENWOOD</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={G} data-src="#" alt="BIRKENSTOCK" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BIRKENSTOCK</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={H} data-src="#" alt="NIKE" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>NIKE</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={I} data-src="#" alt="BINATONE" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BINATONE</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={J} data-src="#" alt="SILVERCREST" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>SILVERCREST</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="42" onMouseEnter={handleHomeMouseEnter} onMouseLeave={handleHomeMouseLeave}>
                            <a id="nav_main_category_42" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="42" data-parent-id="0" data-has-sb="1">HOME APPLIANCES</a>
                                <div id="mega_menu_content_42" class="dropdown-menu mega-menu-content" style={{ display: isHomeDropdownVisible ? "block" : "none" }} onMouseEnter={handleHomeMouseEnter} onMouseLeave={handleHomeMouseLeave}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_45" href="#" className="second-category nav-main-category" data-id="45" data-parent-id="42" data-has-sb="0">BLENDERS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_44" href="#" className="second-category nav-main-category" data-id="44" data-parent-id="42" data-has-sb="0">COOKWARE SETS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_46" href="#" className="second-category nav-main-category" data-id="46" data-parent-id="42" data-has-sb="0">KITCHENWARES</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_43" href="#" className="second-category nav-main-category" data-id="43" data-parent-id="42" data-has-sb="0">HOME DECOR</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={K} data-src="#" alt="BLENDERS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>BLENDERS</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={L} data-src="#" alt="NON-STICK COOKWARE SET" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>NON-STICK COOKWARE SETS</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="41" onMouseEnter={handleElectronicMouseEnter} onMouseLeave={handleElectronicMouseLeave}>
                            <a id="nav_main_category_41" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="41" data-parent-id="0" data-has-sb="0">ELECTRONIC WEARABLES</a>
                                <div id="mega_menu_content_41" className="dropdown-menu mega-menu-content" style={{ display: isElectronicDropdownVisible ? "block" : "none" }} onMouseEnter={handleElectronicMouseEnter} onMouseLeave={handleElectronicMouseLeave}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_55" href="#" className="second-category nav-main-category" data-id="55" data-parent-id="41" data-has-sb="0">SMARTWATCHES</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_54" href="#" className="second-category nav-main-category" data-id="54" data-parent-id="41" data-has-sb="0">SMART EARBUDS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_56" href="#" className="second-category nav-main-category" data-id="56" data-parent-id="41" data-has-sb="0">WEARABLE CAMERA</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_53" href="#" className="second-category nav-main-category" data-id="53" data-parent-id="41" data-has-sb="0">HEADSETS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={O} data-src="#" alt="SMARTWATCHES" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>SMARTWATCHES</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={M} data-src="#" alt="HEADSETS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>HEADSETS</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={N} data-src="#" alt="EARBUDS" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>EARBUDS</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                    
                            <li className="nav-item dropdown" data-category-id="47" onMouseEnter={handleKidsMouseEnter} onMouseLeave={handleKidsMouseLeave}>
                            <a id="nav_main_category_47" href="/product-category" className="nav-link dropdown-toggle nav-main-category" data-id="47" data-parent-id="0" data-has-sb="0">KIDS ARENA</a>
                                <div id="mega_menu_content_47" className="dropdown-menu mega-menu-content" style={{ display: isKidsDropdownVisible ? "block" : "none" }} onMouseEnter={handleKidsMouseEnter} onMouseLeave={handleKidsMouseLeave}>
                                    <div className="row">
                                        <div className="col-8 menu-subcategories col-category-links">
                                            <div className="card-columns">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_65" href="#" className="second-category nav-main-category" data-id="65" data-parent-id="47" data-has-sb="0">ART SETS</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_64" href="#" className="second-category nav-main-category" data-id="64" data-parent-id="47" data-has-sb="0">TOYS & GAMES</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_66" href="#" className="second-category nav-main-category" data-id="66" data-parent-id="47" data-has-sb="0">KID'S CLOTHING</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <a id="nav_main_category_63" href="#" className="second-category nav-main-category" data-id="63" data-parent-id="47" data-has-sb="0">KID'S SHOES</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-category-images">
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={P} data-src="#" alt="ART SET" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>ART SET</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={Q} data-src="#" alt="KID'S CLOTHING" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>KID'S CLOTHING</span>
                                                </a>
                                            </div>
                                            <div className="nav-category-image">
                                                <a href="#">
                                                    <img src={R} data-src="#" alt="KID'S SHOES" className="img-fluid ls-is-cached lazyloaded" />
                                                    <span>KID'S SHOES</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                    </div>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navigation;
