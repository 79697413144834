import "./register.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import HeaderCopy from "../../components/header/HeaderCopy";
import RegistrationFormFields from './RegistrationFormFields';

const TOTAL_STEPS = 11;

const Register = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [shopDescription, setShopDescription] = useState("");
    const [contact, setContact] = useState("");
    const [businessCategory, setBusinessCategory] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [currentStep, setCurrentStep] = useState(1); // Step 1 as default

    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSignUp, setShowSignUp] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [showIcon, setShowIcon] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    const handleChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setShowIcon(value.length > 0); // Show icon when user starts typing
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Move to the next step
    const nextStep = () => {
        if (currentStep < TOTAL_STEPS) setCurrentStep((prev) => prev + 1);
    };

    // Move to the previous step
    const prevStep = () => {
        if (currentStep > 1) setCurrentStep((prev) => prev - 1);
    };

    // Calculate the width percentage of the progress bar based on the current step
    const calculateProgressWidth = () => {
        return `${(currentStep / TOTAL_STEPS) * 100}%`;
    };

    // hides the "or signup with" on the start of the registration page
    const handleStartHereClick = () => {
        setShowSignUp(false); // Hide the sign-up section when "Start Here" is clicked
        // Additional logic for handling the transition to the next form
    };

    // handles the checkbox for agree to terms
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    // function to validate contact
    const validateContact = (contact) => {
        return contact.length === 10 && /^[0-9]+$/.test(contact); // Basic validation for contact number
    };

    // handles submitting of registration forms
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Clear previous error message
        setSuccessMessage(''); // Clear previous success message


        // Handle potential errors in user input
        if (!validateContact(contact)) {
            setLoading(false);
            setErrorMessage("Please enter a valid 10-digit contact number.");
            return;
        }

        if (!isCheckboxChecked) {
            setLoading(false);
            setErrorMessage("Please accept the terms and conditions to proceed.");
            return;
        }

        if (password !== confirmPassword) {
            setLoading(false);
            setErrorMessage("Please confirm your password correctly.");
            return;
        } 
        
        // Make the API call to submit the registration form
        axios
            .post(`${process.env.REACT_APP_Base_url}/sellers/register`, {
                firstName,
                lastName,
                email,
                password,
                companyName,
                location,
                contact,
                businessCategory,
                shopDescription,
            })
            .then((res) => {
                setLoading(false);

                if (res.data.status === 201) {
                document.getElementById("finish").checked = true;
                
                // Inform the user to check their email for activation instructions
                setSuccessMessage(
                    "Your account has been created successfully! An activation email has been sent to your email address. Please confirm your account."
                  );
                } else {
                    setErrorMessage(res.data.message || "An unexpected error occurred.");
                }
            })
            .catch((err) => {
            
                setLoading(false);
            
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message;
                
                // Check for specific error messages from the backend
                setErrorMessage(
                    errorMessage ===
                    "The mobile number you entered has already been used. Enter a new number"
                      ? errorMessage
                      : "An error occurred. Please try again later."
                  );
            } else {
                  setErrorMessage("An error occurred. Please try again later.");
            }
        });
    };

    return (
        <>
            <HeaderCopy />

            <section className="vh-75 d-flex justify-content-center align-items-center" style={{ backgroundColor: "whitesmoke", paddingTop: "200px" }}>
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="register-seller-container">
                                        <input className="c-checkbox" type="checkbox" id="start" />
                                        <input className="c-checkbox" type="checkbox" id="progress2" />
                                        <input className="c-checkbox" type="checkbox" id="progress3" />
                                        <input className="c-checkbox" type="checkbox" id="progress4" />
                                        <input className="c-checkbox" type="checkbox" id="progress5" />
                                        <input className="c-checkbox" type="checkbox" id="progress6" />
                                        <input className="c-checkbox" type="checkbox" id="progress7" />
                                        <input className="c-checkbox" type="checkbox" id="progress8" />
                                        <input className="c-checkbox" type="checkbox" id="progress9" />
                                        <input className="c-checkbox" type="checkbox" id="progress10" />
                                        <input className="c-checkbox" type="checkbox" id="finish" />
                                        <div className="c-form__progress"></div>

                                        <div className="c-formContainer">
                                            <div className="c-welcome">Welcome, {firstName}!</div>
                                            <form className="c-form" onSubmit="{handleSubmit}">
                                                {/* Render the current form based on the index */}
                                                {/* Pass props to the FormStep component */}
                                                <RegistrationFormFields
                                                    firstName={firstName}
                                                    setFirstName={setFirstName}
                                                    lastName={lastName}
                                                    setLastName={setLastName}                                                                                           
                                                    location={location}
                                                    setLocation={setLocation}
                                                    email={email}
                                                    setEmail={setEmail}
                                                    companyName={companyName}
                                                    setCompanyName={setCompanyName}
                                                    shopDescription={shopDescription}
                                                    setShopDescription={setShopDescription}
                                                    contact={contact}
                                                    setContact={setContact}
                                                    businessCategory={businessCategory}
                                                    setBusinessCategory={setBusinessCategory}
                                                    password={password}
                                                    setPassword={setPassword}
                                                    confirmPassword={confirmPassword}
                                                    setConfirmPassword={setConfirmPassword}
                                                    showPassword={showPassword}
                                                    setShowPassword={setShowPassword}
                                                    isCheckboxChecked={isCheckboxChecked}
                                                    setIsCheckboxChecked={setIsCheckboxChecked}
                                                    currentStep={currentStep}
                                                    setCurrentStep={setCurrentStep}
                                                    showIcon={showIcon}
                                                    handleChange={handleChange}
                                                    togglePasswordVisibility={togglePasswordVisibility}
                                                    handleCheckboxChange={handleCheckboxChange}
                                                    handleSubmit={handleSubmit}
                                                    loading={loading}
                                                    formError={formError}
                                                    setFormError={setFormError}
                                                />

                                                {/* Navigation buttons */}
                                                
                                                <div className="c-form__progress" style={{ width: calculateProgressWidth() }}></div>
                                                
                                    

                                                
                                                <label className="c-form__toggle" for="start" onClick={handleStartHereClick}>Start Here<span className="c-form__toggleIcon"></span></label>
                                                {showSignUp && (  // Render the sign-up section only if showSignUp is true
                                                    <div className="col-lg-12 mt-4 text-center">
                                                        <h6>Or Sign Up With</h6>
                                                        <ul className="list-unstyled social-icon mb-0 mt-3">
                                                            <li className="list-inline-item">
                                                                <a href="#/" className="rounded">
                                                                    <i className="mdi mdi-facebook" title="Facebook" />
                                                                </a>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <a href="#/" className="rounded">
                                                                    <i className="mdi mdi-google-plus" title="Google" />
                                                                </a>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <a href="#/" className="rounded">
                                                                    <i className="mdi mdi-github-circle" title="Github" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}

                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Register;