import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import { ClipLoader } from "react-spinners";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState(''); // State for error messages
    const [successMessage, setSuccessMessage] = useState(''); // State for success messages
    const [formData, setFormData] = useState({
        old_password: '',
        password: '',
        password_confirm: '',
    });
    

    // retrieve seller's credentials from session storage
    useEffect(() => {
        const tk = sessionStorage.getItem("seller_token");
        setToken(tk);
    }, []);

    // handles changing form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleJwtExpired = () => {
        setErrorMessage('Session Expired', 'Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/");
    };

    // handles form submitting
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message
    
        // Check if passwords match
        if (formData.password !== formData.password_confirm) {
            setErrorMessage('The Confirm Password field does not match the Password field.');
            setLoading(false);
            return;
        }
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Base_url}/sellers/change_password`, 
                {
                    oldPassword: formData.old_password,
                    newPassword: formData.password,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Check if the response contains a JWT expiration message
            if (response.data && response.data.message === "jwt expired") {
                handleJwtExpired();
                return;
            }
    
            setLoading(false);
            if (response.status === 200) {
                setSuccessMessage(response.data.message); // Set success message
                // Clear form data after successful password change
                setFormData({
                    old_password: '',
                    password: '',
                    password_confirm: ''
                });
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                // Check for JWT expiration
                if (error.response.status === 401 && error.response.data.message === "jwt expired") {
                    handleJwtExpired();
                } else {
                    setErrorMessage(error.response.data.message || 'An error occurred');
                }
            } else {
                setErrorMessage('Network error');
            }
            console.error('Error changing password:', error);
        }
    };


    return (
        <>
            <HeaderCopy />

            {/* Change Seller Password start */}
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                                </ol>
                            </nav>
                            <h1 className="page-title">Settings</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            <div className="row-custom">
                                <div className="profile-tabs">
                                    <ul className="nav">
                                        <li className="nav-item ">
                                            <a className="nav-link" href="/settings">
                                                <span>Update Profile</span>
                                            </a>
                                        </li>
                                        <li className="nav-item active">
                                            <a className="nav-link" href="/change-password">
                                                <span>Change Password</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-12 col-md-9">
                            <div className="row-custom">
                                <div className="profile-tab-content">
                                    {/* Display error and success messages here */}
                                    {errorMessage && (
                                        <div className="m-b-15">
                                            <div className="alert alert-danger alert-dismissable alert-messages">
                                                <ul>
                                                    <li>{errorMessage}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="m-b-15">
                                            <div className="alert alert-success alert-dismissable alert-messages">
                                                <ul>
                                                    <li>{successMessage}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    <form onSubmit={handleSubmit}>                                                            
                                        <div className="form-group">
                                            <label className="control-label">Old Password</label>
                                            <input 
                                                type="password" 
                                                name="old_password" 
                                                className="form-control form-input" 
                                                value={formData.old_password} 
                                                placeholder="Old Password" 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">New Password</label>
                                            <input 
                                                type="password" 
                                                name="password" 
                                                className="form-control form-input" 
                                                value={formData.password} 
                                                placeholder="New Password" 
                                                minlength="8" maxlength="255" 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Confirm Password</label>
                                            <input 
                                                type="password" 
                                                name="password_confirm" 
                                                className="form-control form-input" 
                                                value={formData.password_confirm} 
                                                placeholder="Confirm Password" 
                                                maxlength="255" 
                                                onChange={handleChange} 
                                                required 
                                            />
                                        </div>
                                        <button 
                                            type="submit" 
                                            className="btn btn-md btn-custom"
                                            disabled={loading}
                                            
                                        >
                                            {loading ? ( 
                                                <ClipLoader color="#ffffff" loading={loading} size={20} />
                                            ) : (
                                                "Change Password"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Change Seller's Password */}

            <Footer />
        
        </>
    );
};

export default ChangePassword;