import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";

const Privacy = () => {

  return (
    <>
      <HeaderCopy />

      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="blog-content">
              <nav class="nav-breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
              </nav>
              <h1 class="page-title">Privacy Policy</h1>
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-text-content post-text-responsive">
                    <p><strong>PRIVACY POLICY</strong></p>
<p><span>Last updated April 14, 2023</span></p>
<p><span>This privacy notice for Vincee (Company, we', 'us", or 'our), describes how and why we might collect, store, use, and/or share (process') your information when you use our services (Services'), such as when you:</span></p>
<p><span>Visit our website at http://vinceeimports.tech, or any website of ours that links to this privacy notice</span></p>
<p><span>Engage with us in other related ways, including any sales, marketing, or events</span></p>
<p><span>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at fgyamfi15@gmail.com.</span></p>
<p><span>SUMMARY OF KEY POINTS</span></p>
<p><span>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section vou are looking for.</span></p>
<p><span>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Vinceeimports and the Services. the choices you make. and the products and features you use. Learn more albout personal information you disclose to us</span></p>
<p><span>Do we process any sensitive personal information? We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law.</span></p>
<p><span>Leam more about sensitive information we process</span></p>
<p><span>Do we receive any information from third parties? We do not receive any information from third parties.</span></p>
<p><span>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Leam more about how we process your information</span></p>
<p><span>In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when</span></p>
<p><span>and mu whom we snare your seisonanonnaton</span></p>
<p><span>How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</span></p>
<p><span>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more</span></p>
<p><span>How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection lavis.</span></p>
<p><span>Want to learn more about what Vincee does with any information we collect? Review the privacy notice in full.</span></p>
<p><span>TABLE OF CONTENTS</span></p>
<ol>
<li><span>WHAT INFORMATION DO WE COLLECT?</span></li>
<li><span>HOW DO WE PROCESS YOUR INFORMATION?</span></li>
<li><span>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></li>
<li><span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></li>
<li><span>HOW LONG DO WE KEEP YOUR INFORMATION?</span></li>
<li><span>HOW DO WE KEEP YOUR INFORMATION SAFE?</span></li>
<li><span>WHAT ARE YOUR PRIVACY RIGHTS?</span></li>
<li><span>CONTROLS FOR DO-NOT-TRACK FEATURES</span></li>
<li><span>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></li>
<li><span>DO WE MAKE UPDATES TO THIS NOTICE?</span></li>
<li><span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></li>
<li><span>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></li>
</ol>
<p><span>1. WHAT INFORMATION DO WE COLLECT?</span></p>
<p><span>Personal information you disclose to us</span></p>
<p><span>In Short: We collect personal information that you provide to us.</span></p>
<p><span>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services,</span></p>
<p><span>when you participate in activities on the Services. or otherwise when you contact us</span></p>
<p><span>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and reatures you use. The personal information we collect may include the following:</span></p>
<p><span>names</span></p>
<p><span>phone numbers</span></p>
<p><span>email addresses</span></p>
<p><span>usernames</span></p>
<p><span>billing addresses</span></p>
<p><span>debit/credit card numbers</span></p>
<p><span>contact preferences</span></p>
<p><span>Sensitive Intormation. When necessary with your consent or as otherwise permitted ov applicable law. we process the following categones of sensitive information:</span></p>
<p><span>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your</span></p>
<p><span>navment instriment All navment data is stored o Pavstack You mav find their nrivacy notice linkishere ntinsinavstack commhiterms deiterms</span></p>
<p><span>Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.</span></p>
<p><span>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></p>
<p><span>2. HOW DO WE PROCESS YOUR INFORMATION?</span></p>
<p><span>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also</span></p>
<p><span>brocess vour nonston oronerosmoses yin your consem</span></p>
<p><span>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></p>
<p><span>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></p>
<p><span>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</span></p>
<p><span>• To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, retums, and exchanges made through the Services.</span></p>
<p><span>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></p>
<p><span>In Short: We may share information in specific situations described in this section andior with the following third parties.</span></p>
<p><span>We may need to share your personal information in the following situations:</span></p>
<p><span>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a</span></p>
<p><span>portion of our business to another company</span></p>
<p><span>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></p>
<p><span>In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</span></p>
<p><span>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain protile intormation about you from your social media provider. The protile information we receive may vary depending on the social media provider concemed, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</span></p>
<p><span>We will use the information we receive onlv for the ourooses that are described in this onivacy notice or that are otherwise made clear to vou on the relevant Services. Please note that we</span></p>
<p><span>do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to</span></p>
<p><span>understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</span></p>
<p><span>5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p>
<p><span>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</span></p>
<p><span>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account</span></p>
<p><span>With us</span></p>
<p><span>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives, then we will securely store your personal information and isolate it from any further processing until deletion is</span></p>
<p><span>possible</span></p>
<p><span>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
<p><span>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</span></p>
<p><span>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybernminals, or other unauthonsed third parties will not be able to dete at our security and improperty collect, access, steal, or modity your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the</span></p>
<p><span>Services within a secure envronment</span></p>
<p><span>7. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
<p><span>In Short: You may review, change, or terminate your account at any time.</span></p>
<p><span>If you are located in the EA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authonty or UK data protection authonty.</span></p>
<p><span>If you are located in Switzerland. you may contact the Federal Data Protection and Information Commissioner.</span></p>
<p><span>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</span></p>
<p><span>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawtul processing grounds other than consent.</span></p>
<p><span>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP or UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you - for example. to send you service-related messages</span></p>
<p><span>that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketng purposes</span></p>
<p><span>Account Information</span></p>
<p><span>it you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>
<p><span>Log in to your account settings and update your user account.</span></p>
<p><span>Contact us using the contact information provided.</span></p>
<p><span>Upon your request to terminate your account, we will de activate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud. troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p>
<p><span>If you have questions or comments about your privacy rights, you may email us at fgyamfi15@gmail.com.</span></p>
<p><span>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
<p><span>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (DNT) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As</span></p>
<p><span>such, we do not currently respond to D browser signals or any other mechanism that automatically communicates your choice not to be tracked online. It a standard for online tracking Is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>
<p><span>10. DO WE MAKE UPDATES TO THIS NOTICE?</span></p>
<p><span>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</span></p>
<p><span>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised date and the updated version will be effective as soon as it is accessible.</span></p>
<p><span>If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information</span></p>
<p><span>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p>
<p><span>If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by email at</span></p>
<p><span>or by post to:</span></p>
<p><span>Vincee Imports</span></p>
<p><span>If you have any further questions or comments, you may also contact us by post at the following corporate address:</span></p>
<p><span>Vincee Imports</span></p>
<p><span>Mr Adjei, Kwabenya - Biazo street</span></p>
<p><span>Dome Kwabenya, Greater Accra 00233</span></p>
<p><span>Chanz</span></p>
<p><span>Phone: <a href="tel:+233 542 059 074">+233 542 059 074</a></span></p>
<p><span>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></p>
<p><span>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review update. or delete your personal information. please fill out and submit a data subiect access request.</span></p>                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
      <Footer />
    </>
  );
};

export default Privacy;
