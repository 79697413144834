import React from 'react';
import Icon from "../../components/icon/Icon";

const RegistrationFormFields = ({ 
    firstName,
    setFirstName,
    lastName,
    setLastName,                                                                                           
    location,
    setLocation,
    email,
    setEmail,
    companyName,
    setCompanyName,
    shopDescription,
    setShopDescription,
    contact,
    setContact,
    businessCategory,
    setBusinessCategory,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    isCheckboxChecked,
    setIsCheckboxChecked,
    currentStep,
    setCurrentStep,
    showIcon,
    handleChange,
    togglePasswordVisibility,
    handleCheckboxChange,
    handleSubmit,
    loading,
    formError
}) => {

    switch (currentStep) {
        case 1:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="firstname">
                        <input
                            type="text"
                            id="firstname"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(2)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                            
                        <span className="c-form__groupLabel">Enter your first name.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
        
        case 2:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="surname">
                        <input
                            type="text"
                            id="surname"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />

<div className="c-form__navigation">
            {/* PREVIOUS Button */}
            <label
                className="c-form__nav-button c-form__prev"
                role="button"
                onClick={() => setCurrentStep(1)}
            >
                <span className="c-form__prevIcon"></span>
            </label>
            {/* NEXT Button */}
            <label
                className="c-form__nav-button c-form__next"
                role="button"
                onClick={() => setCurrentStep(3)}
            >
                <span className="c-form__nextIcon"></span>
            </label>
        </div>
                        {/* PREVIOUS Button - Moves back to the previous step 
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(1)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step 
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(3)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>*/}
                        
                        <span className="c-form__groupLabel">Enter your surname.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
                
        case 3:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="location">
                        <input
                            type="text"
                            id="location"
                            className="c-form__input"
                            placeholder=" "
                            pattern="[^\s]+"
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(2)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(4)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                            
                        <span className="c-form__groupLabel">Where is your business located?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );

        case 4:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="femail">
                        <input
                            type="email"
                            id="femail"
                            className="c-form__input"
                            placeholder=" "
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                                    
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(3)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(5)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
                        
                        <span className="c-form__groupLabel">What's your business email?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
            
        case 5:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="company-name">
                        <input
                            type="text"
                            id="company-name"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$" //".*\S+.*"
                            required
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
    
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(4)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(6)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
    
                        <span className="c-form__groupLabel">What's the name of your business?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
            
        case 6:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="business-category">
                        <select
                            id="business-category"
                            className="c-form__input"
                            placeholder=" "
                            required
                            onChange={(e) => setBusinessCategory(e.target.value)}
                        >
                            <option value>Choose...</option>
                            <option value="General Goods">General Goods</option>
                            <option value="Consumer Electronics">Consumer Electronics</option>
                            <option value="Fashion & Apparel">Fashion & Apparel</option>
                            <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                            <option value="Entertainment">Entertainment</option>
                            <option value="Household Goods">Household Goods</option>
                        </select>
    
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(5)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(7)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>
    
                        <span className="c-form__groupLabel">Which category does your business fall?</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );
            
        case 7:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="shop-description">
                        <input
                            type="text"
                            id="shop-description"
                            className="c-form__input"
                            placeholder=" "
                            pattern="^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$" //".*\S+.*"
                            required
                            value={shopDescription}
                            onChange={(e) => setShopDescription(e.target.value)}
                        />

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(6)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(8)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>

                        <span className="c-form__groupLabel">Tell us about your business.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>    
            );

        case 8:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="contact">
                        <input
                            type="tel"
                            id="contact"
                            className={`c-form__input ${formError ? 'is-invalid' : ''}`} //"c-form__input"
                            placeholder=" "
                            pattern="^0\d{9}$"
                            required
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        {formError && <p className="text-danger">{formError}</p>}

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(7)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(9)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>

                        <span className="c-form__groupLabel">Enter your mobile number.</span>
                        <b className="c-form__border"></b>
                    </label>
                </div>
            );

        case 9:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="fpass">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="fpass"
                            className="c-form__input"
                            placeholder=" "
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ paddingRight: '2em' }} // Adjust padding for icon space
                        />
                        {/* Conditionally render the eye icon if showIcon is true */}
                        {showIcon && (
                            <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', position: 'absolute', right: '8px' }}>
                            {showPassword ? <Icon name="VscEyeClosed" size="1.5em" /> : <Icon name="VscEye" size="1.5em" />}
                            </span>
                        )}
                        
                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(8)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>
    
                        {/* NEXT Button - Moves to the next step */}
                        <label
                            className="c-form__next"
                            role="button"
                            onClick={() => setCurrentStep(10)} // Moves to the next step
                        >
                            <span className="c-form__nextIcon"></span>
                        </label>

                        <span className="c-form__groupLabel">Create your password.</span>
                        <b class="c-form__border"></b>
                    </label>
                </div>
            );

        case 10:
            return (
                <div className="c-form__group">
                    <label className="c-form__label" htmlFor="confirm-fpass">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirm-fpass"
                            className="c-form__input"
                            placeholder=" "
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {password !== confirmPassword && (
                            <p className="text-danger">Password mismatch.</p>
                        )}

                        {/* PREVIOUS Button - Moves back to the previous step */}
                        <label
                            className="c-form__prev"
                            role="button"
                            onClick={() => setCurrentStep(9)} // Moves to the previous step
                        >
                            <span className="c-form__prevIcon"></span>
                        </label>

                        {/* NEXT Button - finish button */}
                        <label className="c-form__next" htmlFor="finish" role="button" onClick={handleSubmit} disabled={!isCheckboxChecked}>
                            {loading ? (
                                <span className="c-form__nextIcon spinner"></span>
                            ) : (
                                <span className="c-form__nextIcon"></span>
                            )}
                        </label>

                        <span className="c-form__groupLabel">Confirm your password.</span>
                        <b class="c-form__border"></b>
                    </label>

                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="custom-control custom-checkbox custom-checkbox-terms">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                    required
                                    onChange={handleCheckboxChange}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1">
                                    I accept{" "}
                                    <a href="/terms" className="text-primary">
                                        Terms & Conditions
                                    </a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            );
            // Add other cases for more form steps...
              default:
                return <div>Form Step {currentStep} is not yet implemented.</div>;
    }
};

export default RegistrationFormFields;