import React from "react";
import payment from "../../assets/img/payment-item.png";
//import logo from "../../assets/img/logo.png";
import Icon from "../icon/Icon";

const Footer = () => {
    return (
        <>
            <footer className="footer spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer__about">
                                <div className="footer__about__logo">
                                    <a href="/">
                                        <img 
                                            src="https://res.cloudinary.com/dfexgcu3h/image/upload/v1728734674/Vincee/logo/logo-removebg-preview_uj1ybf.png" 
                                            alt="logo" 
                                            className="zoomable" 
                                            width="auto" 
                                            height={80} 
                                            style={{ width: '250px', height: '80px', maxWidth: "100%", transition: "transform .3s ease-in-out" }} 
                                        />
                                    </a>
                                </div>
                                <ul>
                                    <li>
                                        <strong>NewMarket</strong> is an online store which gives you variety of brands to shop quality products at very affordable prices.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                            <div className="footer__widget">
                                <h6>Quick Links</h6>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact</a>
                                    </li>
                                    <li>
                                        <a href="/shops">Shops</a>
                                    </li>
                                    <li>
                                        <a href="/help">Help Center</a>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <a href="/terms">Terms & Conditions</a>
                                    </li>
                                    <li>
                                        <a href="/privacy">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="footer__widget">
                                <h6>Newsletter</h6>
                                <form action="#">
                                    <input type="text" placeholder="Enter your email" />
                                    <button type="submit" className="site-btn">
                                        Subscribe
                                    </button>
                                </form>
                                <div className="footer__widget__social">
                                    <h6>Follow Us</h6>
                                    <a href="#">
                                        <Icon name="FaFacebookF" size="1em" color="#365899" />
                                    </a>
                                    <a href="#">
                                        <Icon name="FaInstagram" size="1em" color="#F06292" />
                                    </a>
                                    <a href="#">
                                        <Icon name="FaXTwitter" size="1em" color="#000000" />
                                    </a>
                                    <a href="https://chat.whatsapp.com/CLtGudvPCh4HajcG5uRpQR">
                                        <Icon name="FaWhatsapp" size="1em" color="#008b8b" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer__copyright">
                                <div className="footer__copyright__text">
                                    <p>
                                        Copyright 2024 Vincee - All Rights Reserved.                    
                                    </p>
                                </div>
                                <div className="footer__copyright__payment">
                                    <img src={payment} alt="payment" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;