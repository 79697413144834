import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import axios from "axios";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { TextField, FormControl, Button } from "@mui/material";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from "../../components/icon/Icon";


const Login = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [password, setPassword] = useState("");
    const [identifier, setIdentifier] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [identifierError, setIdentifierError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const toast = useRef<Toast | null>(null);
    const location_data = useLocation();
    const navigate = useNavigate();

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
  
    // Show toast notifications
    const show = (message: string, status: number) => {
        const severity = status === 200 ? "success" : status === 401 || status === 400 ? "error" : "warn";
        if (toast.current) { // Check if toast.current is defined
            toast.current.show({
                severity,
                summary: message,
                detail: "",
            });
        }
    };

    // Handles form submission of Shoppers
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIdentifierError(false);
        setPasswordError(false)

        // Validate form fields
        if (identifier === "") {
        setIdentifierError(true);
        }

        if (password == '') {
        setPasswordError(true)
        }

        // Only proceed if there are no validation errors
        if (identifier && password) {
            axios
            .post(`${process.env.REACT_APP_Base_url}/shoppers/login`, {
                identifier,
                password,
            })
            .then((res) => {
                show(res.data.message, res.data.status);
                setIsSubmitting(false);

                if (res.data.status === 200) {
                    sessionStorage.setItem("shopper_token", res.data.token);
                    sessionStorage.setItem("shopper_id", res.data.user.id);

                    // Concatenate first name and last name
                    const fullName = `${res.data.user.first_name} ${res.data.user.last_name}`;
                    sessionStorage.setItem("shopper_name", fullName); // Set full name in session storage

                    navigate("/");
                } else if (res.data.needActivation) {
                    // Display activation message to the user
                    setIsSubmitting(false);
                    show(res.data.message, 401); // Show warning for activation
                }
            })
            .catch((err) => {
                setIsSubmitting(false);
                console.log(err);

                if (err.response && err.response.data) {
                    show(err.response.data.message, err.response.data.status || 400); // Show error message
                } else {
                    show("An unexpected error occurred.", 500); // Fallback error message and status
                } 
            });
        } else {
            setIsSubmitting(false); // Reset submitting state if validation fails
        }
    };

    return (
        <>
            <Toast ref={toast} />
      
            <HeaderCopy />
        
            {/* Login page start  Start */}
            <section className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-content">
                                <div className="title-wrapper text-center">
                                    <ol className="breadcrumbs d-flex align-items-center justify-content-center" style={{ padding: '0' }}>
                                        <li itemProp="itemListElement">
                                            <a itemProp="item" href="/">
                                                <span itemProp="name">Home</span>
                                                <span className="delimiter" style={{ padding: '0 10px' }}>/</span>
                                            </a>
                                            <meta itemProp="position" content="1" />
                                        </li>
                                        <li itemProp="itemListElement">
                                            <a itemProp="item" href="/login">
                                                <span itemProp="name">Account</span>
                                            </a>
                                            <meta itemProp="position" content="2" />
                                        </li>
                                    </ol>
                                    <h1 className="page-title" style={{ fontSize: '60px' }}>Log in</h1>
                                </div>
                                <div className="auth-container">
                                    <div className="auth-box" style={{ background: 'transparent' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <React.Fragment>
                                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                                        <FormControl fullWidth margin="normal">
                                                            <TextField 
                                                                label="Email or phone"
                                                                onChange={e => setIdentifier(e.target.value)}
                                                                variant="outlined"
                                                                color="secondary"
                                                                type="text"
                                                                value={identifier}
                                                                error={identifierError}
                                                                helperText={identifierError ? "Email or phone is required" : ""}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth margin="normal">
                                                            <TextField 
                                                                label="Enter your password"
                                                                onChange={e => setPassword(e.target.value)}
                                                                variant="outlined"
                                                                color="secondary"
                                                                type={showPassword ? "text" : "password"}
                                                                value={password}
                                                                error={passwordError}
                                                                helperText={passwordError ? "Password is required" : ""}
                                                                InputProps={{
                                                                endAdornment: (
                                                                    <Button onClick={togglePasswordVisibility}>
                                                                    {showPassword ? "Hide" : "Show"}
                                                                    </Button>
                                                                ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <div className="form-group text-right">
                                                            <a href="/forgot-password" className="link-forgot-password" style={{ fontSize: '14px', top: '10px', marginBottom: '-100px', marginRight: '0px', position: 'relative' }}>Forgot Password?</a>
                                                        </div>
                                                        <Button 
                                                            variant="outlined" 
                                                            color="secondary" 
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? (
                                                                <>
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    <span className="visually-hidden">Logging in...</span>
                                                                </>
                                                                ) : (
                                                                "Login"
                                                            )}
                                                        </Button>
                                            
                                                    </form>
                                                    <small>Need an account? <Link to="/register?role=shopper" style={{ color: 'blue' }}>Register here</Link></small>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
                </div>
            </section>
            {/* Login page end */}
     
            <Footer />
        </>
    );
};

export default Login;