import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Styles       
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import "./style-1.1.min.css";
import "./style-1.0.min.css";

// Prime React libraries theme
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

// Pages
import Cart from "./pages/cart/Cart";
import Products from "./pages/products/Products";
import Login from "./pages/login/Login";
import Search from "./pages/search/Search";
import ProtectedRoute from "./ProtectedRoute";
import Details from "./pages/details/Details";
import Register from "./pages/register/Register";
import Checkout from "./pages/checkout/Checkout";
import Dashboard from "./pages/dashboard/Dashboard";
import Activation from "./pages/activation/Activation";
import LoginAdmin from "./pages/login-admin/LoginAdmin";
import ProductByCategory from "./pages/product-category/ProductByCategory";
import ProductBySection from "./pages/product-section/ProductBySection";
import AdminDashboard from "./pages/admin-dashboard/AdminDashboard";
import SellerDashboard from "./pages/seller-dashboard/SellerDashboard";
import InactiveProductList from "./pages/product-inactive/InactiveProductList";
import ActiveProductList from "./pages/product-active/ActiveProductList";
import EditProduct from "./pages/edit-product/EditProduct";
import Wishlist from "./pages/wishlist/Wishlist";
import Terms from "./pages/terms/Terms";
import About from "./pages/about/About";
import Privacy from "./pages/privacy/Privacy";
import Help from "./pages/help/Help";
import Contact from "./pages/contact/Contact";
import Payment from "./pages/payment/Payment";
import ChangePassword from "./pages/change-password/ChangePassword";
import Orders from "./pages/orders/Orders";
import Settings from "./pages/settings/Settings";
import Profile from "./pages/profile/Profile";
import SellerProfile from "./pages/seller-profile/SellerProfile";
import Donate from "./pages/donate/Donate";
import Shops from "./pages/shops/Shops";

// Components
import ScrollUpButton from './components/slider/ScrollUpButton';
import Newsletter from './components/newsletter/Newsletter';

// Route Configuration
const routes = [
    { path: "/", element: <Dashboard /> },
    { path: "/products", element: <Products /> },
    { path: "/shops", element: <Shops /> },
    { path: "/login", element: <Login /> },
    { path: "/search", element: <Search /> },
    { path: "/details", element: <Details /> },
    { path: "/register", element: <Register /> },
    { path: "/activation/:type", element: <Activation /> }, // Dynamic route for activation (shoppers or sellers)
    { path: "/login-admin", element: <LoginAdmin /> },
    { path: "/product-category", element: <ProductByCategory /> },
    { path: "/product-section", element: <ProductBySection /> },
    { path: "/admin-dashboard", element: <AdminDashboard /> },
    { path: "/seller-dashboard/*", element: <SellerDashboard /> },
    { path: "/product-inactive", element: <InactiveProductList /> },
    { path: "/product-active", element: <ActiveProductList /> },
    { path: "/edit-product/:productId", element: <EditProduct /> },
    { path: "/terms", element: <Terms /> },
    { path: "/about", element: <About /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/help", element: <Help /> },
    { path: "/contact", element: <Contact /> },
    { path: "/payment", element: <Payment /> },
    { path: "/orders", element: <Orders /> },
    { path: "/wishlist", element: <Wishlist /> },
    { path: "/settings", element: <Settings /> },
    { path: "/profile/*", element: <Profile /> },
    { path: "/seller-profile/*", element: <SellerProfile /> },
    { path: "/donate", element: <Donate /> },
    { path: "/change-password", element: <ChangePassword /> },
    { path: "/shop", element: <Shops /> },
];

function App() {
    return (
        <Router>
            <ScrollUpButton />
            <Routes>
                {routes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
                <Route element={<ProtectedRoute />}>
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                </Route>
                <Route path="*" element={<h1 style={{ textAlign: "center" }}>Page Not Found</h1>} />
            </Routes>
            <Newsletter />
        </Router> 
    );
}

export default App;