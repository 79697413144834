import React from "react";
import user from "../../assets/img/user.png";
import Icon from "../icon/Icon";

const Navigation = () => {
    // get user credentials from session storage
    const SellerName = sessionStorage.getItem("seller_name");
    const ProfilePicture = sessionStorage.getItem("profile_picture");
    const sellerId = sessionStorage.getItem("seller_id");

    // logout function
    const logOut = (event) => {
        event.preventDefault(); // Prevent the default form submission behavior 
        sessionStorage.clear();
        window.location.href = "/";
    };

    if (!SellerName) return null; // Do not render Nav if not logged in

    return (
        <li className="nav-item dropdown profile-dropdown p-r-0">
            <a href="javascript:void(0)" className="nav-link dropdown-toggle a-profile" data-toggle="dropdown" aria-expanded="false" tabIndex="0">
                <img 
                    src={ProfilePicture || user} 
                    alt={SellerName} 
                />
                {SellerName}
                <Icon name="BiChevronDown" color="#888" style={{ display: 'inline-flex' }} />
            </a>
            <ul className="dropdown-menu" role="menu">
                <li>
                    <a href="/seller-dashboard">
                        <Icon name="RxDashboard" color="#606060" style={{ width: '22px' }} />  
                        Dashboard
                    </a>
                </li>
                <li>
                    <a href="/seller-profile">
                        <Icon name="MdOutlineFaceRetouchingNatural" color="#606060" style={{ width: '22px' }} />
                        Profile
                    </a>
                </li>
                <li>
                    <a href="#">
                        <Icon name="FaShoppingBasket" color="#606060" style={{ width: '22px' }} />
                        Orders
                    </a>
                </li>
                <li>
                    <a href="#">
                        <Icon name="MdEmail" color="#606060" style={{ width: '22px' }} />
                        Messages&nbsp;
                    </a>
                </li>
                <li>
                    <a href="/settings">
                        <Icon name="FaCog" color="#606060" style={{ width: '22px' }} />
                        Settings
                    </a>
                </li>
                <li>
                    <form className="form-logout">
                        <button type="submit" className="btn-logout" onClick={logOut}>
                            <Icon name="MdLogout" color="#606060" />
                            &nbsp;&nbsp;Logout
                        </button>
                    </form>
                </li>
            </ul>
        </li>
    );
};

export default Navigation;