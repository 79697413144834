import React, { useEffect, useState } from "react";
import SellerNavMobile from '../../components/seller dashboard/SellerNavMobile'; // navigation for sellers (when logged in) on mobile devices

// styles and Icons
import Icon from "../icon/Icon";
import "./navigation.css";

interface MobileNavigationProps {
    isMenuBarVisible: boolean;
    openLoginModal: (role: "shoppers" | "sellers") => void;
    handleMenuClick: () => void;
    logOut: () => void;
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({ isMenuBarVisible, openLoginModal, handleMenuClick, logOut }) => {
    const ShopperName: string | null = sessionStorage.getItem("shopper_name");
    const SellerName: string | null = sessionStorage.getItem("seller_name");
    const [activeMenu, setActiveMenu] = useState<number | null>(null);
    const [currentMenuLabel, setCurrentMenuLabel] = useState<string>('');
    const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
    const [currentSubMenuLabel, setCurrentSubMenuLabel] = useState<string>('');
    const [animationClass, setAnimationClass] = useState<string>('');

    // handle menu navigation animation
    useEffect(() => {
        if (activeMenu === null && activeSubMenu === null) {
        setAnimationClass('');
        } else {
        setAnimationClass('slide-in-right');
        }
    }, [activeMenu, activeSubMenu]);

    const handleSubMenuClick = (id: number, label: string) => {
        setAnimationClass('slide-in-right');
        setActiveMenu(activeMenu === id ? null : id);
        setCurrentMenuLabel(label);
        setActiveSubMenu(null);
        setCurrentSubMenuLabel('');
    };

    const handleSubSubMenuClick = (id: number, label: string) => {
        setAnimationClass('slide-in-right');
        setActiveSubMenu(activeSubMenu === id ? null : id);
        setCurrentSubMenuLabel(label);
    };

    const handleBackClick = () => {
        if (activeSubMenu) {
        setActiveSubMenu(null);
        setCurrentSubMenuLabel('');
        } else if (activeMenu) {
        setAnimationClass('slide-out');
        setActiveMenu(null);
        setCurrentMenuLabel('');
        }
    };
  
    return (
        <>  
            {/* Mobile navigation menu */} 
            <div id="navMobile" className={`nav-mobile ${isMenuBarVisible ? 'nav-mobile-open' : ''}`}>
                <div className="nav-mobile-sc">
                    <div className="nav-mobile-inner">
                        
                        {/* Sell now button on mobile devices */}
                        <div className="row">
                            <div className="col-sm-12 mobile-nav-buttons">
                                <a 
                                    href="javascript:void(0)" 
                                    className="btn btn-md btn-custom btn-block close-menu-click" 
                                    data-toggle="modal" 
                                    data-target="#loginModal"
                                    onClick={() => {
                                        handleMenuClick(); // Close the mobile navigation menu
                                        openLoginModal("sellers"); // Open the login modal for sellers
                                    }}
                                >
                                    Sell Now
                                </a>
                            </div>
                        </div>
                        {/* Sell now button on mobile devices */}

                        {/* Menu bar on mobile devices */}
                        <div className="row">
                            <div className={`col-sm-12 nav-mobile-links ${animationClass}`}>
                                {(activeMenu || activeSubMenu) && (
                                    <div id="navbar_mobile_back_button" onClick={handleBackClick}>
                                        <a href="javascript:void(0)" className="nav-link" data-id="1" data-category-name={activeSubMenu ? currentSubMenuLabel : currentMenuLabel}>
                                            <strong>
                                                <Icon name="BiChevronLeft" size="1em" style={{ position: 'relative', float: 'left', marginRight: '6px' }} />
                                                {activeSubMenu ? currentSubMenuLabel : currentMenuLabel}
                                            </strong>
                                        </a>
                                    </div>
                                )}  
                                {!activeMenu && !activeSubMenu && (
                                    <>
                                        <ul id="navbar_mobile_categories" className="navbar-nav">
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="1" data-parent-id="0" onClick={() => handleSubMenuClick(1, 'MEN')}>MEN<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="2" data-parent-id="0" onClick={() => handleSubMenuClick(2, 'WOMEN')}>WOMEN<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="8" data-parent-id="0" onClick={() => handleSubMenuClick(8, 'BRANDS')}>BRANDS<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="42" data-parent-id="0" onClick={() => handleSubMenuClick(42, 'HOME APPLIANCES')}>HOME APPLIANCES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="41" data-parent-id="0" onClick={() => handleSubMenuClick(41, 'ELECTRONIC WEARABLES')}>ELECTRONIC WEARABLES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                            <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="47" data-parent-id="0" onClick={() => handleSubMenuClick(47, 'KIDS ARENA')}>KIDS ARENA<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                        </ul>
                                        <ul id="navbar_mobile_links" className="navbar-nav">
                                            <li className="nav-item"><a href="/wishlist" className="nav-link">Wishlist</a></li>
                                            <li className="nav-item"><a href="/products" className="nav-link">Products</a></li>
                                            <li className="nav-item"><a href="/shops" className="nav-link">Shops</a></li>
                                            <li className="nav-item"><a href="/contact" className="nav-link">Contact</a></li>
                                            <li className="nav-item"><a href="/about" className="nav-link">About</a></li>
                                            <li className="nav-item"><a href="/donate" className="nav-link">Donate</a></li>
                                            {SellerName ? (  
                                                <SellerNavMobile />
                                            ) : (
                                                <>
                                                    {!ShopperName && (
                                                        <li className="nav-item">
                                                            <a href="/register" className="nav-link">
                                                                Register
                                                            </a>
                                                        </li>
                                                    )}
                                                    {ShopperName ? (
                                                        <li className="nav-item">
                                                            <a href="/" className="nav-link" onClick={logOut}>
                                                                Logout
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        <li className="nav-item">
                                                            <a 
                                                                href="javascript:void(0)" 
                                                                className="nav-link" 
                                                                data-toggle="modal" 
                                                                data-target="#loginModal" 
                                                                onClick={() => {
                                                                    handleMenuClick(); // Close the mobile navigation menu
                                                                    openLoginModal("shoppers"); // Open the login modal for shoppers
                                                                }}
                                                            >
                                                                Login
                                                            </a>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </>
                                )}
                                {activeMenu === 1 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="9" data-parent-id="1" onClick={() => handleSubSubMenuClick(9, 'ACCESSORIES')}>ACCESSORIES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="4" data-parent-id="1" onClick={() => handleSubSubMenuClick(4, 'CLOTHING')}>CLOTHING<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="5" data-parent-id="1" onClick={() => handleSubSubMenuClick(5, 'FOOTWEAR')}>FOOTWEAR<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                    </ul>
                                )}
                                {activeMenu === 2 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="10" data-parent-id="2" onClick={() => handleSubSubMenuClick(10, 'ACCESSORIES')}>ACCESSORIES<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="6" data-parent-id="2" onClick={() => handleSubSubMenuClick(6, 'CLOTHING')}>CLOTHING<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                        <li className="nav-item"><a href="javascript:void(0)" className="nav-link" data-id="7" data-parent-id="2" onClick={() => handleSubSubMenuClick(7, 'FOOTWEAR')}>FOOTWEAR<Icon name="BiChevronRight" size="1em" style={{ float: 'right', position: 'relative' }} /></a></li>
                                    </ul>
                                )}
                                {activeMenu === 8 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="36" data-parent-id="8">Hermès</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="32" data-parent-id="8">BIRKENSTOCK</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="39" data-parent-id="8">BINATONE</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="34" data-parent-id="8">NIKE</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="38" data-parent-id="8">BURBERRY</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="40" data-parent-id="8">SILVERCREST</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="33" data-parent-id="8">ADIDAS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="37" data-parent-id="8">BALENCIAGA</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="41" data-parent-id="8">KENWOOD</a></li>
                                    </ul>
                                )}
                                {activeMenu === 42 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="45" data-parent-id="42">BLENDERS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="46" data-parent-id="42">KITCHENWARES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="44" data-parent-id="42">COOKWARE SETS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="43" data-parent-id="42">HOME DECOR</a></li>
                                    </ul>
                                )}
                                {activeMenu === 41 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="55" data-parent-id="41">SMARTWATCHES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="56" data-parent-id="41">WEARABLE CAMERA</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="54" data-parent-id="41">SMART EARBUDS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="53" data-parent-id="41">HEADSETS</a></li>
                                    </ul>
                                )}
                                {activeMenu === 47 && !activeSubMenu && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="65" data-parent-id="47">ART SETS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="66" data-parent-id="47">KID'S CLOTHING</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="64" data-parent-id="47">TOYS & GAMES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link" data-id="63" data-parent-id="47">KID'S SHOES</a></li>
                                    </ul>
                                )}
                                {/* SUB-SUBMENU ITEMS */}
                                {activeSubMenu === 9 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">COLOGNE</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">HATS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">JEWELRIES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">TIES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">OTHERS</a></li>
                                    </ul>
                                )}
                                {activeSubMenu === 4 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">JACKETS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">TOPS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SHORTS/TROUSERS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">OTHERS</a></li>
                                    </ul>
                                )}
                                {activeSubMenu === 5 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SNEAKERS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SHOES</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SLIPPERS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">OTHERS</a></li>
                                    </ul>
                                )}
                                {activeSubMenu === 10 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">HAIR WEARS & BONNETS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">NECKLACES & ANKLETS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">PERFUMES & BEAUTY</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">BAGS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">EYEWEAR, BEAUTY & COSMETICS</a></li>
                                    </ul>
                                )}
                                {activeSubMenu === 6 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">FEMALE TOPS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">FEMALE TROUSERS & SHORTS</a></li>
                                    </ul>
                                )}
                                {activeSubMenu === 7 && (
                                    <ul id="navbar_mobile_categories" className={`navbar-nav ${animationClass}`}>
                                        <li className="nav-item"><a href="#" className="nav-link">All</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SHOES & HEELS</a></li>
                                        <li className="nav-item"><a href="#" className="nav-link">SNEAKERS</a></li>
                                    </ul>
                                )}
                            </div> 
                        </div>
                        {/* Menu bar on mobile devices */}
                    </div>
                </div>
                
                {/* Navigation footer */}
                <div className="nav-mobile-footer">
                    <ul>
                        <li>
                            <a href="#" className="facebook" target="_blank">
                                <Icon name="FaFacebookF" size="1em" color="#365899" />
                            </a>
                        </li>
                        <li>
                            <a href="#" className="twitter" target="_blank">
                                <Icon name="FaXTwitter" size="1em" color="#000000" />
                            </a>
                        </li>
                        <li>
                            <a href="#" className="instagram" target="_blank">
                                <Icon name="FaInstagram" size="1em" color="#F06292" />
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/233542059074?text=Hello%20Vincee" className="whatsapp" target="_blank">
                                <Icon name="FaWhatsapp" size="1em" color="#008b8b" />
                            </a>
                        </li>
                    </ul>
                </div>
                {/* Navigation footer */}
            </div>
        </>
    );
};

export default MobileNavigation;